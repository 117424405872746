/* Note that this is a regular css file.
I don't know how to make it an scss file yet without breaking project
CSS imports are in index.tsx
*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbarContainer.collapse:not(.show):not(.collapsing) {
  /* .collapse:not(.show) wasn't working because something else had !important on it in scss */
  display: none !important;
}

/* AG Grid overrides (TODO move somewhere else) */
.ag-root .btn-sm {
  font-size: 0.6rem;
  padding: 0.1rem 0.25rem;
}

/* TODO Fix this in a better way with Sass or something... */
.ag-root .col-1 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-2 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-3 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-4 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-6 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-7 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-8 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-9 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-10 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-11 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-root .col-12 {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-tooltip-hiding {
  opacity: 0;
}