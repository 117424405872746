:root {
  --blue-color: rgb(19, 161, 255);
  --green-color: rgb(70, 255, 132);
  --red-color: rgb(255, 0, 0);
}

.all-around-padding {
  padding-right: 20px;
  padding-bottom: 10px;
}

.black-bottom-border {
  border-bottom: black;
}

.fcp-main-page-container {
  height: 100%;
  padding: 0;
}

/*For the div an AG grid is in */
.grid-wrapper {
  height: 99%;
}

/*for substep grid*/
.installation-cell {
  width: 110px;
  height: 24px;
  text-align: center;
}

/*Used for installation cell*/
.initials-dropdown {
  height: 24px;
  width: 100%;
}

/*Used for the title of a section (but under header)*/
.modal-section-header {
  padding: 20px;
  font-size: 25px;
}

.page-container {
  padding-top: 10px;
  /* padding-bottom: 0%; */
}

.set-trigger-button {
  border: none;
  background-color: transparent;
}


/*BUTTONS*/
.blue-button {
  background-color: white;
  color: var(--blue-color);
  border: 2px solid var(--blue-color);
  border-radius: 5px;
}

.disabled-button{
  pointer-events: none;
}

.gray-button {
  background-color: white;
  color: rgb(134, 134, 134);
  border: 2px solid rgb(134, 134, 134);
  border-radius: 5px;
}

.green-button {
  background-color: white;
  color: rgb(70, 255, 132);
  border: 2px solid rgb(70, 255, 132);
  border-radius: 5px;
}

.red-button {
  background-color: white;
  color: rgb(255, 0, 0);
  border: 2px solid rgb(255, 0, 0);
  border-radius: 5px;
}


/*Loader component*/
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 3px solid;
  border-color: var(--blue-color) transparent var(--blue-color) transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


/*Substeps config*/
/*Pad the title row (with an add button)
right above grids in fcp and substep page*/
.add-component {
  padding-left:0px;
  padding-bottom: 10px;
}

.rotate-title {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  height: 60px;
  padding-right: 0;
}

.greenlight-rotate-override {
  background-color: transparent;
}

.fcp-cell {
  font-size: 10px;
}

/* Note the spacing is different between these!! It is important */
.ag-theme-balham .ag-cell.fcp-cell {
  padding-left: 1px;
  padding-right: 1px;
  text-align: center;
}

/* Note the spacing is different between these!! It is important */
.ag-theme-balham .ag-header-cell.fcp-header-cell {
  padding-left: 0;
  padding-right: 0;
}

/*DO NOT REMOVE THE !IMPORTANT
AG Grid will disregard attributes without it.*/
.paused-fcp {
  background-color: LightGray !important;
}

.category-row {
  background-color: aqua !important;
}


/*Custom toggle*/
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 21px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  background-color: white;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: var(--blue-color);
}


/* Date text strings styling for substeps */
.invisible-date-field {
  color: transparent;
}

.paused-substep {
  background-color: LightGray !important;
}

.date-style-common {
  border: 0ch;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

.clear-installation-date-button {
  color: black;
  background-color: transparent;
  border: 0px;
}

.clear-installation-date-confirm-button {
  color: red;
  background-color: transparent;
  border: 0px;
}

.clear-greenlight-date-button {
  color: black;
  background-color: transparent;
  border: 0px;
  padding-bottom: 3px;
}

.clear-greenlight-date-confirm-button {
  color: red;
  background-color: transparent;
  border: 0px;
  padding-bottom: 3px;
}

.navbar-height {
  height: 40px;
}