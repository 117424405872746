.profileImage {
  border-radius: 50%;
  height: 30px;
}

// Using id selector because using className on the tag that has the id affects its parent for whatever reason
.profileDropdown {
  // This is prefixed with :global to use a non-css-modules inner class or id
  :global #navbarScrollingDropdown {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
  }
}
