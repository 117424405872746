.collapsibleHeaderWrapper {
  position: relative;
}
.collapseButton {
  position: absolute;
  /* Modal is 1050, so make it less than 1000 */
  z-index: 900;
  padding: 0px;
  font-size: 12px;
  transform-origin: 7px center 0px;
  right: 20px;
}

.linkContainer {
  white-space: nowrap;
}

.standoutLink{
  /* 'bold' was too bold, used https://stackoverflow.com/a/25960699/13815107  */
  font-weight: 600;
}