.collapsibleHeaderWrapper {
  position: relative;
}
.collapseButton {
  position: absolute;
  /* Modal is 1050, so make it less than 1000 */
  z-index: 900;
  padding: 0px;
  font-size: 12px;
  transform-origin: 7px center 0px;
  right: 20px;
}

.linkContainer {
  white-space: nowrap;
  cursor: pointer;
}

.brand {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.searchlightLogo{
  padding-top: 1px;
  /* Hack because the image has too much space on the sides of the circle */
  margin-right: -7px;
}