.scoreTitle {
    display: flex;
    justify-content: center;
    font-size: 1.20em;
}

.scoreLabel {
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
}

.scoreValue {
    font-weight: bold;
    /* Bootstrap blue */
    color: #007bff;
    padding-left: 5px;
    padding-right: 5px;
}

.earnedOutOfTotal {
    padding-left: 5px;
    padding-right: 5px;
}

.centered {
    text-align: center;
}