@import '../../../variables';

.tasksGridWarning {
  /* TODO don't use !important, it was the easiest way I could override AG grid styles */
  color: $ljiRed !important;
}

.container {
  padding-bottom: 10px;
}
