.unitOfMeasure {
  height: 30px;
  padding: 10px;
}

.autoPushDescription {
  font-style: italic;
  font-size: .9em;
}

.autoPushDescriptionValue {
  font-style: normal;
  font-weight: bold;
  font-size: .9em;
}