.dotsDropdown {
  // This is prefixed with :global to use a non-css-modules inner class or id
  :global .dropdown-toggle::after {
    display: none;
  }

  // This is prefixed with :global to use a non-css-modules inner class or id
  :global .dropdown-toggle {
    padding-left: 5px;
    padding-right: 3px;
    /* Blue was getting picked up from something else */
    color: #212529;
  }
}