.date-picker {
  max-width: 45px;
  margin-left: 5px;
  height: 90%;
}

.date-picker > div.react-datepicker__input-container input{
  width: 100%;
  height: 25px;
  text-align: right;
}

.date-picker-unsaved > div.react-datepicker__input-container input{
  background-color: lightcoral;
}