.automaticallyAdded {
  background-color: lightgrey;
  // A bunch of pasted CSS from Reach-select to make it look similar
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  color: black;
  font-size: 85%;
  overflow: hidden;
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}