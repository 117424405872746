@import 'src/variables';

.goalSuccessColor {
  background-color: green;
}

.goal75PlusColor {
  background-color: lightgreen;
}

.goal50PlusColor {
  background-color: yellow;
}

.goal25PlusColor {
  background-color: yellow;
}

.goal0PlusColor {
  background-color: orange;
}

.goalFailColor {
  background-color: $ljiRed;
}
