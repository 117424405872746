.combinedScoresHeader {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.2rem;
}
.combinedScoresPercent {
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 10px;
}

/* .collapsibleHeaderWrapper {
  position: relative;
} */
.collapseButton {
  position: absolute;
  /* Modal is 1050, so make it less than 1000 */
  z-index: 900;
  padding: 0px;
  font-size: 12px;
  transform-origin: 7px center 0px;
  top: 20px;
  right: -0px;
}

.dividerLine {
  margin-top: 0px;
  margin-bottom: 3px;

}
