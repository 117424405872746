@import '../../../../variables';

.teamScoreSummary {
  width: auto;
}

.col {
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
}

.label {
  font-size: 0.8em;
}

.headers {
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
}

.weightedScoreValue {
  text-align: center;
}

.completedTasksValue {
  text-align: center;
  color: green;
  padding: 0;
}

.pastDueValue {
  text-align: center;
  color: $ljiRed;
  padding: 0;
}

.remainingPastDueValue {
  text-align: center;
  color: darkgoldenrod;
  padding: 0;
}
