.taskSummaryText {
  /* subtract taskSummaryIcon's width */
  width: calc(100% - 12px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.taskSummaryIcon {
  /* width is about 12px */
  display: inline-block;
}
