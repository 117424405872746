.dateHeader {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.1rem;
}
.combinedScoresHeader {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5rem;
}
.combinedScoresPercent {
  font-size: 2rem;
  font-weight: bold;
  padding-left: 10px;
}
